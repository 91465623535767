import clsx from "clsx";
import React from "react";
import { get, hasIn } from "lodash";
import IconBox from "@root/common/IconBox";
import LocaleService from "@root/services/locale";

import styles from "./styles.module.scss";

function Pricing({ data }) {
  const { t } = LocaleService.useLocale();
  const hasVip = hasIn(data, ['vip_price']);
  const hasSpecial = hasIn(data, ['special_price']);

  const price = get(data, ["price"]);
  const vip = get(data, ["vip_price"]);
  const special = get(data, ["special_price"]);
  const percent = get(data, ["vip_percent_off"]);
  
  return (
    <div className={clsx(styles.pricing, hasVip && styles.hasvip)}>
      <div className={styles.pricing_regular}>
        <span className={clsx(styles.pricing_original, hasSpecial && styles.pricing_strike)} >{price}</span>
        {hasSpecial && ( <span className={styles.pricing_special}>{special}</span> )}
      </div>
      {hasVip && <div className={styles.pricing_vip}>
        <span className={styles.pricing_vip_percent}><IconBox className={styles.pricing_vip_icon} name="crown" />{percent}</span>
        <span className={styles.pricing_vip_value}>{vip}</span>
      </div>}
    </div>
  );
}

export default Pricing;
