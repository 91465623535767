import React from "react";
import IconBox from "@root/common/IconBox";
import LocaleService from "@root/services/locale";

import styles from "./styles.module.scss";

function VipStrip({value, percent}) {
  const { t } = LocaleService.useLocale();
  
  return (
    <div className={styles.vipstrip}>
      <div className={styles.vipstrip_value}>
        <IconBox name="crown-outline" className={styles.vipstrip_icon} />
        <span className={styles.vipstrip_amount}>{value}</span>
      </div>
      <span className={styles.vipstrip_percent}>{percent}</span>
    </div>
  );
}

export default VipStrip;
