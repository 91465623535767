import config from '@root/config';
import {find, get} from 'lodash';
import {setCookie} from 'cookies-next';
import {useEffect, useState} from 'react';
import {isValidObj} from '@root/libs/utils';
import AuthService from '@root/services/auth';
import QuoteService from '@root/services/quote';
import LocaleService from '@root/services/locale';
import StorageService from '@root/services/storage';
import RequestService from '@root/services/request';
import {of, catchError, BehaviorSubject, firstValueFrom, switchMap} from 'rxjs';

class DLocation {
  constructor() {
    this.$behaviourData = new BehaviorSubject({});
    this.$behaviourRegion = new BehaviorSubject({});
    this.$behaviourCity = new BehaviorSubject({});

    if (!this.instance) this.instance = this;
    return this.instance;
  }

  regions(list, regionId) {
    return find(list, {key: regionId});
  }

  cities(list, regionId) {
    return find(list, {key: regionId});
  }

  findRegion(value) {
    let regions = get(value, ['regions', 'data'], []);
    let regionId = get(value, ['regions', 'value'], null);
    return find(regions, {key: regionId});
  }

  findCity(value) {
    let regions = get(value, ['regions', 'data'], []);
    let region = find(regions, region => region?.city);
    let cityId = get(region, ['value'], null);
    return find(region?.city, {key: cityId});
  }

  get isExpressLocation() {
    let cookieloc = this.storedLocation();
    return isValidObj(cookieloc);
    // return get(cookieloc, ['express'], false);
  }

  useLocationData() {
    const [locData, setLocData] = useState({});
    const [locCity, setLocCity] = useState({});
    const [locRegion, setLocRegion] = useState({});

    useEffect(() => {
      let dataSub = this.$behaviourData.subscribe(setLocData);
      let regionSub = this.$behaviourRegion.subscribe(setLocRegion);
      let citySub = this.$behaviourCity.subscribe(setLocCity);

      return () => {
        dataSub.unsubscribe();
        regionSub.unsubscribe();
        citySub.unsubscribe();
      };
    }, []);

    return {
      locData,
      locRegion,
      locCity,
    };
  }

  storedLocation() {
    try {
      let location = StorageService.read(config.locationKey);
      return JSON.parse(location) || {};
    } catch (err) {
      return {};
    }
  }

  setLocation(data) {
    StorageService.write(config.locationKey, JSON.stringify(data));
  }

  fetchLocations({regionId, cityId, addrId = null}) {
    return firstValueFrom(
      this.fetchNextDel({regionId: regionId, cityId: cityId}),
    ).then(result => {
      let localData = result?.data;
      let region = this.findRegion(localData);
      let city = this.findCity(localData);

      this.$behaviourData.next(localData);
      this.$behaviourRegion.next(region);
      this.$behaviourCity.next(city);

      this.setLocation({
        addrId,
        slot: localData?.next,
        express: localData?.regions?.express_delivery_available,
        city: {
          key: city?.key,
          label: city?.label,
        },
        region: {
          key: region?.key,
          label: region?.label,
        },
      });

      setCookie(config.locationKey, city?.key);
    });
  }

  fetchNextDel({regionId, cityId}) {
    return QuoteService.$quote.pipe(
      switchMap(quote => {
        let param = `${regionId || '0'}-${cityId || '0'}-${quote || '0'}`;
        return this.nextDeliveryRequest(param).pipe(
          catchError(error => of(error)),
        );
      }),
    );
  }

  nextDeliveryRequest = param => {
    return AuthService.isAuth
      ? RequestService.$authGet(
          `${config.base_path}${LocaleService.current}/rest/V1/customapi/next_delivery_date/${param}`,
        )
      : RequestService.$get(
          `${config.base_path}${LocaleService.current}/rest/V1/customapi/guest/next_delivery_date/${param}`,
        );
  };
}
const DLocationService = new DLocation();
export default DLocationService;
