import clsx from 'clsx';
import React from 'react';
import {find, get, map} from 'lodash';
import IconBox from '@root/common/IconBox';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';

function Configs({product, configs, onSelect}) {
  const {t} = LocaleService.useLocale();

  const hasConfigs = () => {
    return !(configs.length === 0);
  };

  const configValue = () => {
    return get(product, ['product_option'], '');
  };

  const weightInfo = () => {
    return get(product, ['additional_data', 'weight_info'], '');
  };

  const onChangeSelect = (event) => {
    let value = event.target.value;
    let found = find(configs, {id: value});
    onSelect(found);
  }

  return (
    <div className={styles.configs}>
      <div className={clsx(styles.configs_box, hasConfigs() && styles.hasconfigs)}>
        <span className={styles.configs_config}>
          {configValue()}{' '}
          <span className={styles.configs_weightinfo}>{weightInfo()}</span>
        </span>
        {hasConfigs() && (
          <IconBox name="chevron-down" className={styles.configs_chev} />
        )}
      </div>
      {hasConfigs() && (
        <select onChange={onChangeSelect} className={styles.configs_select}>
          {map(configs, (config, index) => {
            return <option key={config.id} value={config.id}>{config.product_option}</option>;
          })}
        </select>
      )}
    </div>
  );
}

export default Configs;
