import Head from 'next/head';
import { forwardRef } from 'react';
import { isValidString } from '@root/libs/utils';

const Page = ({ title, description, children }, ref) => {

  return (
    <>
      <Head> 

        <meta property="al:ios:url" content="barakat://links/" />
        <meta property="al:ios:app_store_id" content="1510001708" />
        <meta property="al:ios:app_name" content="Barakat" />
        <meta property="og:title" content="Barakat apps" />
        <meta property="og:type" content="website" />
        <meta property="al:android:url" content="barakat://links/" />
        <meta property="al:android:package" content="com.barakat" />
        <meta property="al:android:app_name" content="Barakat" />
        <meta property="og:title" content="Barakat apps" />
        <meta property="og:type" content="website" />

        <link preconnect="https://media.barakatfresh.ae" crossOrigin=""></link>
        <link preconnect="https://static.barakatfresh.ae" crossOrigin=""></link>

        <meta name="google-site-verification" content="D4tyXFfiVwHVbp7wksiVU3m8Vr8LIF3lM_e7u-IuXow" />
        <meta name="facebook-domain-verification" content="1qk0c8qcyst81yp3w7l033v7fwah7k" />


        <link rel="canonical" href="https://barakatfresh.ae/en/" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <link rel="dns-prefetch" href="https://barakatfresh.ae/"></link>
        <meta name="Petal-Search-site-verification" content="cd7f31f1b5"></meta>
        <link rel="shortcut icon" type="image/x-icon" href="https://media.barakatfresh.ae/media/favicon/stores/3/BKT_PNG.png"></link>
        <link rel="icon" type="image/x-icon" href="https://media.barakatfresh.ae/media/favicon/stores/3/BKT_PNG.png"></link>
        <meta name="description" content={isValidString(description) ? description : 'Barakat Fresh: Order fresh groceries online in Dubai, Sharjah, Abu Dhabi, Ajman &amp; Al Ain. Leading online seller of fresh fruits, vegetables, juices, ice cream, Soups &amp; salad'} />
        <title>{isValidString(title) ? title : 'Barakat Fresh: Order Groceries Online ' +
        'Delivery in Dubai, Sharjah, Abu Dhabi, Ajman & Al ' + 'Ain | Fruits | Vegetables | Juices'}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
      </Head>
      {children}
    </>
  )
}

export default forwardRef(Page);