import React from "react";
import { includes, indexOf, map, toLower } from "lodash";
import { isValidObj } from "@root/libs/utils";
import LocaleService from "@root/services/locale";

import styles from "./styles.module.scss";
import clsx from "clsx";

function Badges({ data }) {
  const { t } = LocaleService.useLocale();
  
  return (
    <div className={styles.badges}>
      {map(data, (item, index) => {
        if(!isValidObj(item)) return null;
        return (
          <span key={index} className={styles.badges_item}>{item}</span>
        )
      })}
    </div>
  );
}

export default Badges;
