import clsx from 'clsx';
import {get, map} from 'lodash';
import React, {useRef, useState} from 'react';
import LocaleService from '@root/services/locale';
import IconBox from '@root/common/IconBox';
import PopModal from '@root/common/PopModal';

import styles from './styles.module.scss';

function Configs({product, configs, onSelect}) {
  const {t} = LocaleService.useLocale();
  const modalRef = useRef(null);

  const hasConfigs = () => {
    return !(configs.length === 0);
  };

  const configValue = () => {
    return get(product, ['product_option'], '');
  };

  const weightInfo = () => {
    return get(product, ['additional_data', 'weight_info'], '');
  };

  const onClickConfigs = () => {
    if (hasConfigs()) modalRef.current.show();
  };

  const onClickConfig = config => {
    onSelect(config);
    modalRef.current.hide();
  };

  const onClickClose = () => {
    modalRef.current.hide();
  };

  return (
    <>
      <div onClick={onClickConfigs} className={clsx(styles.configs, hasConfigs() && styles.hasconfigs)}>
        <span className={styles.configs_config}>
          {configValue()}{' '}
          <span className={styles.configs_weightinfo}>{weightInfo()}</span>
        </span>
        {hasConfigs() && (
          <IconBox name="chevron-down" className={styles.configs_chev} />
        )}
      </div>
      {hasConfigs() && (
        <PopModal ref={modalRef} bgaction>
          <div className={styles.configlist}>
            <a onClick={onClickClose} className={styles.configlist_close}>
              <IconBox name="close" />
            </a>
            <div className={styles.configlist_title}>
              <span className={styles.configlist_title_text}>
                {get(product, ['name'])}
              </span>
            </div>
            <ul className={styles.configlist_items}>
              {map(configs, (config, index) => {
                let isactive = product.id === config.id;
                return (
                  <li
                    onClick={() => onClickConfig(config)}
                    key={index}
                    className={clsx(
                      styles.configlist_item,
                      isactive && styles.active,
                    )}>
                    <span className={styles.configlist_value}>
                      {get(config, ['product_option'], '')}
                    </span>
                    <span className={styles.configlist_weight}>
                      {get(config, ['additional_data', 'weight_info'], '')}
                    </span>
                    <span className={styles.configlist_price}>
                      {get(config, ['price'], '')}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </PopModal>
      )}
    </>
  );
}

export default Configs;
