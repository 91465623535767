import React from 'react';
import IconBox from '@root/common/IconBox';

import styles from './styles.module.scss';

function ExpressDelivery({icon, size = 10}) {
  return (
    <div className={styles.express} style={{fontSize: size}}>
      {icon && <IconBox name={icon} className={styles.express_icon} />}
      <span className={styles.express_text}>90 mins</span>
    </div>
  );
}

export default ExpressDelivery;
