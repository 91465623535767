import Image from 'next/image'
import React, { useEffect, useState } from 'react'

function CImage({ src, ...rest }) {
  const [imgSrc, set_imgSrc] = useState(src);

  useEffect(() => {
    set_imgSrc(src);
  }, [src]);

  const onLoadHandle = (event) => {
    setTimeout(() => {
      if(event.target.naturalWidth === 0) set_imgSrc('/placeholder.png');
    }, 1);
  }

  const onErrorHandle = () => {
    set_imgSrc('/placeholder.png');
  }

  return (
    <Image 
      {...rest}
      src={imgSrc}
      onLoad={onLoadHandle}
      onError={onErrorHandle}
    />
  )
}

export default CImage