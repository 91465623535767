import clsx from 'clsx';
import {firstValueFrom} from 'rxjs';
import {toast} from 'react-toastify';
import {useRouter} from 'next/router';
import IconBox from '@root/common/IconBox';
import Spinner from '@root/common/Spinner';
import CartService from '@root/services/cart';
import LocaleService from '@root/services/locale';
import LoggerService from '@root/services/logger';
import React, {useEffect, useState} from 'react';
import EventsService from '@root/services/events';
import {SESSION_TIMEOUT} from '@root/constants/events';
import {get, find, lowerCase, parseInt, isNull} from 'lodash';
import TrackingService, {
  ADD_TO_CART,
  CART_QTY_UPDATE,
  REMOVE_FROM_CART,
} from '@root/services/tracking';

import styles from './styles.module.scss';
// import DLocationService from '@root/services/deliveryLocation';

function AddToCart({product, configOption, from}) {
  const router = useRouter();
  const {t} = LocaleService.useLocale();
  const [active, setActive] = useState(false);
  const [cartItem, setCartItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const {cartData} = CartService.useCartData();

  const maxSaleQty = get(configOption, ['max_sale_qty'], 10000);

  const disabled = () => {
    return (
      !get(configOption, ['is_in_stock'], true) ||
      get(cartItem, ['qty']) === maxSaleQty
    );
  };

  useEffect(() => {
    if (isNull(cartData)) return;

    let items = get(cartData, ['flat'], []);
    let cartitem = setItem(items);
    if (cartitem) setActive(true);
    else setActive(false);
  }, [cartData, configOption]);

  const isInCart = () => {
    return !isNull(cartItem);
  };

  const onPressPlus = () => {
    if (disabled()) return;
    if (loading) return;

    if (isInCart()) {
      let qty = parseInt(get(cartItem, ['qty']));
      updateQuantityAction(qty + 1);
    } else {
      addToCartAction();
    }
  };

  const onPressMinus = () => {
    if (loading) return;

    let qty = parseInt(get(cartItem, ['qty']));
    if (qty > 1) updateQuantityAction(qty - 1);
    else removeItemAction();
  };

  const addToCartAction = () => {
    // if (product?.express_delivery && !DLocationService.isLocation) {
    //   EventsService.trigger({type: 'ASK_EXPRESS'});
    //   return;
    // }
    setActive(true);
    setLoading(true);

    let sku = get(product, ['sku']);
    let option = get(configOption, ['option_id'], null);
    let recos = get(product, ['recommendations'], false);
    firstValueFrom(CartService.addToCart(sku, option, recos, from))
      .then(result => {
        if (!get(result, ['ok'], false)) return;
        setItem(get(result, ['data', 'items'], []));
        TrackingService.triggerEvent(ADD_TO_CART, {
          product,
          cartItem: result.data,
        });
      })
      .catch(error => {
        setActive(false);
        if (error.code === 401) EventsService.trigger({type: SESSION_TIMEOUT});
        else if (error.code !== 404)
          toast.error(get(error, ['message'], t('br_generic_error')));
        LoggerService.logError('Add to cart issue!', error);
      })
      .finally(() => setLoading(false));
  };

  const updateQuantityAction = qty => {
    setLoading(true);
    let id = get(cartItem, ['id']);
    let sku = get(cartItem, ['sku']);

    firstValueFrom(CartService.updateCart(id, sku, qty, from))
      .then(result => {
        if (!get(result, ['ok'], false)) return;
        setItem(get(result, ['data', 'items'], []));
        TrackingService.triggerEvent(CART_QTY_UPDATE, {
          product,
          from: router.asPath,
        });
      })
      .catch(error => {
        if (error.code === 401) EventsService.trigger({type: SESSION_TIMEOUT});
        else if (error.code !== 404)
          toast.error(get(error, ['message'], t('br_generic_error')));
        LoggerService.logError('Update cart issue!', error);
      })
      .finally(() => setLoading(false));
  };

  const removeItemAction = () => {
    let id = get(cartItem, ['id']);

    setLoading(true);
    firstValueFrom(CartService.removeFromCart(id))
      .then(result => {
        if (!get(result, ['ok'], false)) return;

        setActive(false);
        setCartItem(null);

        TrackingService.triggerEvent(REMOVE_FROM_CART, {product, cartItem});
      })
      .catch(error => {
        if (error.code === 401) EventsService.trigger({type: SESSION_TIMEOUT});
        else toast.error(get(error, ['message'], error));
        LoggerService.logError(
          'Remove from cart issue!',
          t('br_generic_error'),
        );
      })
      .finally(() => setLoading(false));
  };

  const setItem = items => {
    let cartitem = find(items, item => {
      return lowerCase(item.sku) === lowerCase(configOption.sku);
    });
    setCartItem(cartitem ? cartitem : null);
    return cartitem;
  };

  return (
    <div className={styles.addtocart}>
      <div className={styles.addtocart_container}>
        <div className={clsx(styles.addtocart_box, active && styles.active)}>
          <div
            onClick={onPressPlus}
            className={clsx(
              styles.addtocart_toggle,
              styles.increment,
              disabled() && styles.disabled,
            )}>
            <IconBox name="plus" className={styles.addtocart_icon} />
          </div>

          <div className={styles.addtocart_textbox}>
            {loading ? (
              <Spinner size={16} />
            ) : (
              <span className={styles.addtocart_value}>
                {get(cartItem, ['qty'])}
              </span>
            )}
          </div>
          <div
            onClick={onPressMinus}
            className={clsx(styles.addtocart_toggle, styles.decrement)}>
            <IconBox name="minus" className={styles.addtocart_icon} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddToCart;
