import React from "react";
import LocaleService from "@root/services/locale";

import styles from "./styles.module.scss";
import clsx from "clsx";

function Spinner({size}) {
  const { t } = LocaleService.useLocale();
  
  return (
    <div className={styles.spinner} style={{height: `${size}px`, width: `${size}px`}}>
      <span className={clsx(styles.rect1, 'bar')}></span>
      <span className={clsx(styles.rect2, 'bar')}></span>
      <span className={clsx(styles.rect3, 'bar')}></span>
      <span className={clsx(styles.rect4, 'bar')}></span>
    </div>
  );
}

export default Spinner;
